import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import { useBooleanState } from '../../hooks';
import GenerateContractDialog from './GenerateContractDialog';

const GenerateContractButton = ({ companyId, contractRequest }) => {
  const [open, toggleDialog] = useBooleanState(false);
  const contractNotReady = contractRequest.status === 'Created';
  const contractAlreadyUploading = contractRequest?.currentContractUploadRequest?.approvedRequest;
  const isDisabled = contractNotReady || contractAlreadyUploading;

  const tooltipText = isDisabled ? 'Contrato no está listo para generarse' : 'Generar documento';

  return (
    <>
      <Tooltip title={tooltipText}>
        <Typography
          sx={{
            zIndex: 9999,
          }}
          component="span"
        >
          <IconButton
            onClick={toggleDialog}
            size="small"
            disabled={isDisabled}
            sx={{
              color: 'primary.main',
              '&:disabled': {
                color: 'grey',
              },
            }}
          >
            <AddIcon />
          </IconButton>
        </Typography>
      </Tooltip>
      {
        open && (
        <GenerateContractDialog
          open={open}
          toggleDialog={toggleDialog}
          companyId={companyId}
        />
        )
      }
    </>
  );
};

GenerateContractButton.propTypes = {
  companyId: PropTypes.string.isRequired,
  contractRequest: PropTypes.shape({
    status: PropTypes.string.isRequired,
    currentContractUploadRequest: PropTypes.shape({
      approvedRequest: PropTypes.bool.isRequired,
    }),
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default GenerateContractButton;
