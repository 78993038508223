import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useSelectedCompany } from '@fingo/lib/hooks';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import REQUEST_SURPLUS_WITHDRAWAL from '@fingo/lib/graphql/mutations/request-surplus-withdrawal';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { BankAccountCard, BankAccountForm } from './bank-accounts-dialog';

const SurplusWithdrawDialog = ({ canWithdraw, open, onClose }) => {
  const selectedCompany = useSelectedCompany();
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);
  const [openBankAccountForm, setOpenBankAccountForm] = useState(false);
  const [requestSurplus, { loading }] = useMutation(
    REQUEST_SURPLUS_WITHDRAWAL,
    {
      variables: {
        companyId: selectedCompany?.id,
        accountId: selectedBankAccount?.id,
      },
      onCompleted: () => {
        onClose();
      },
    },
  );

  const bankAccounts = useMemo(
    () => selectedCompany?.bankAccounts?.filter((a) => a?.active) || [],
    [selectedCompany],
  );

  return (
    <FingoDialog
      open={open}
      handleClose={onClose}
      title="Retiro de excedentes"
      dialogActionButton={
        canWithdraw ? (
          <LoadingButton
            onClick={requestSurplus}
            loading={loading}
            disabled={!selectedBankAccount}
          >
            Continuar
          </LoadingButton>
        ) : null
      }
    >
      {openBankAccountForm ? (
        <BankAccountForm
          bankAccounts={bankAccounts}
          selectedBankAccount={selectedBankAccount}
          onClose={(newBankAccount) => {
            setSelectedBankAccount(newBankAccount);
            setOpenBankAccountForm(false);
          }}
        />
      ) : (
        <Box
          sx={{
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '95%',
          }}
        >
          <Stack spacing={2} sx={{ py: 2, alignItems: 'center' }}>
            {bankAccounts.map((bankAccount) => (
              <BankAccountCard
                key={bankAccount.id}
                bankAccount={bankAccount}
                selectedBankAccount={selectedBankAccount}
                onSelect={(newSelectedBankAccount) => {
                  setSelectedBankAccount(newSelectedBankAccount);
                }}
                onEditHandler={() => setOpenBankAccountForm(true)}
                selectable
              />
            ))}
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                setSelectedBankAccount(null);
                setOpenBankAccountForm(true);
              }}
              fullWidth
              sx={{ width: '50%' }}
            >
              {bankAccounts?.length > 0
                ? 'Agregar otra cuenta'
                : 'Agregar una cuenta'}
            </Button>
          </Stack>
        </Box>
      )}
    </FingoDialog>
  );
};

SurplusWithdrawDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  canWithdraw: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SurplusWithdrawDialog;
