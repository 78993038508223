import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { SummaryCard } from '@fingo/lib/components/cards';
import { useSelectedCompany } from '@fingo/lib/hooks';
import CESSIONS_SUMMARY from '@fingo/lib/graphql/queries/cessions-summary';
import Grid from '@mui/material/Grid';

const CessionsSummaryCards = () => {
  const selectedCompany = useSelectedCompany();
  const { data, loading } = useQuery(CESSIONS_SUMMARY, {
    variables: { companyId: selectedCompany?.id },
    skip: !selectedCompany,
  });

  const { cessionsSummary } = data || {};
  const { amount, invoiceCount, debtAmount, debtCount, availableSurplus } = cessionsSummary || {};

  const cards = useMemo(
    () => [
      {
        title: amount?.amount || 0,
        subtitle: 'Monto total de facturas cedidas',
        isMoney: true,
      },
      { title: invoiceCount || 0, subtitle: 'Cantidad total de facturas operadas' },
      {
        title: debtAmount?.amount || 0,
        subtitle: 'Monto total de facturas en mora',
        isMoney: true,
      },
      { title: debtCount || 0, subtitle: 'Cantidad total de facturas en mora' },
      {
        title: availableSurplus?.amount || 0,
        subtitle: 'Excedentes totales',
        isMoney: true,
      },
    ],
    [amount, invoiceCount, debtAmount, debtCount, availableSurplus],
  );

  return (
    <Grid
      container
      spacing={2}
      columns={{
        xs: 5,
      }}
      direction="row"
    >
      {cards.map((card) => (
        <Grid xs={1} item key={card.subtitle}>
          <SummaryCard {...card} loading={loading} />
        </Grid>
      ))}
    </Grid>
  );
};

export default CessionsSummaryCards;
