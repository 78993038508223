import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { checkRut, prettifyRut } from 'react-rut-formatter';
import PropTypes from 'prop-types';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { nationalTaxServiceCredentialsDialogVar } from '../../apollo/reactive-variables/alerts';
import { isInvoicesFirstLoadVar } from '../../apollo/reactive-variables/invoices';
import useSelectedCompany from '../../hooks/useSelectedCompany';
import useSnackBars from '../../hooks/useSnackBars';
import useUploadCredentials from '../../hooks/useUploadCredentials';
import useGetCountryFromUrl from '../../hooks/useGetCountryFromUrl';
import { validateRfc } from '../../helpers/forms';
import PasswordField from '../fields/PasswordField';
import NationalIdentifierField from '../fields/NationalIdentifierField';

const ACCOUNT_BLOCKED = 'Account temporarily blocked due to several incorrect attempts to login';
const INVALID_CREDENTIALS = 'invalid_credentials';

const VALIDATORS = {
  Chile: (nationalIdentifier) => checkRut(nationalIdentifier),
  Mexico: (nationalIdentifier) => validateRfc(nationalIdentifier),
};

const SETTERS = {
  Chile: (nationalIdentifier) => prettifyRut(nationalIdentifier),
  Mexico: (nationalIdentifier) => nationalIdentifier,
};

const NationalTaxServiceCredentialsForm = ({
  autocomplete,
  source,
  onCompleted,
}) => {
  const { addAlert } = useSnackBars();
  const { t } = useTranslation();
  const countryFromUrl = useGetCountryFromUrl();
  const company = useSelectedCompany();
  const { uploadCredentials } = useUploadCredentials(source);
  const uploadTagManagerEvent = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'credential_upload',
    });
  };

  const initialNationalIdentifier = useMemo(
    () => company?.masterEntity?.displayNationalIdentifier || '',
    [company],
  );

  const country = useMemo(
    () => company?.masterEntity?.country || countryFromUrl,
    [company, countryFromUrl],
  );

  const validationSchema = useMemo(() => {
    const identifierValidation = Yup.string()
      .required(`El ${t('National identifier')} es obligatorio`)
      .test(
        'is-valid-identifier',
        `El ${t('National identifier')} no es válido`,
        (value) => {
          const validator = VALIDATORS[country.name];
          if (validator) {
            return validator(value);
          }
          return true;
        },
      );

    return Yup.object({
      nationalIdentifier: identifierValidation,
      password: Yup.string().required(t('La contraseña es obligatoria')),
    });
  }, [country]);

  const formik = useFormik({
    initialValues: {
      nationalIdentifier: autocomplete ? initialNationalIdentifier : '',
      password: '',
    },
    validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      const setter = SETTERS[country.name];
      const username = setter(values.nationalIdentifier);
      uploadCredentials({
        variables: {
          username,
          password: values.password,
          companyId: autocomplete ? company?.id : null,
        },
        onCompleted: () => {
          addAlert({
            id: 'upload-credentials-success',
            message: 'Credenciales ingresadas con éxito',
            color: 'success',
          });
          resetForm();
          onCompleted(values);
          uploadTagManagerEvent();
          nationalTaxServiceCredentialsDialogVar({
            open: false,
            autocomplete: false,
          });
          isInvoicesFirstLoadVar(true);
          setSubmitting(false);
        },
        onError: ({ message }) => {
          let snackbarMessage;
          if (message === INVALID_CREDENTIALS) {
            snackbarMessage = `El ${t(
              'National identifier',
            )} o la contraseña son incorrectos. Por favor, verifica tus datos e inténtalo de nuevo`;
          } else if (message === ACCOUNT_BLOCKED) {
            snackbarMessage = 'Varios intentos incorrectos de inicio de sesión. Intenta de nuevo más tarde.';
          } else {
            snackbarMessage = 'Hubo un error al verificar tu empresa. Contáctate con nosotros en contacto@fingo.cl';
          }
          addAlert({
            id: 'upload-credentials-error',
            message: snackbarMessage,
            severity: 'error',
            color: 'error',
          });
          setSubmitting(false);
        },
      });
    },
  });

  const { isValid, isSubmitting } = formik;

  return (
    <FormikProvider value={formik}>
      <form
        onSubmit={formik.handleSubmit}
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'center',
          alignSelf: 'center',
        }}
      >
        <NationalIdentifierField
          disabled={autocomplete && Boolean(initialNationalIdentifier)}
        />
        <PasswordField variant="outlined" />
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          sx={{ width: 200, fontWeight: 700, height: 35 }}
          loading={isSubmitting}
          disabled={!isValid || isSubmitting}
        >
          Registrar empresa
        </LoadingButton>
      </form>
    </FormikProvider>
  );
};

NationalTaxServiceCredentialsForm.propTypes = {
  source: PropTypes.string,
  autocomplete: PropTypes.bool,
  onCompleted: PropTypes.func,
};

NationalTaxServiceCredentialsForm.defaultProps = {
  source: null,
  autocomplete: false,
  // eslint-disable-next-line no-unused-vars
  onCompleted: (_) => null,
};

export default NationalTaxServiceCredentialsForm;
