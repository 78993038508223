import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import NationalTaxServiceLogoAndText from '@fingo/lib/components/text/NationalTaxServiceLogoAndText';
import NationalTaxServiceCredentialsForm from '@fingo/lib/components/forms/NationalTaxServiceCredentialsForm';
import useIsMobile from '@fingo/lib/hooks/useIsMobile';
import { Card, Stack, Typography } from '@mui/material';
import useGetRequestingPlatform from '../../hooks/useGetRequestingPlatform';

const NationalTaxServiceCredentials = () => {
  const history = useHistory();
  const requestingPlatform = useGetRequestingPlatform();
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <Card
      width="100%"
      sx={(theme) => ({
        px: 4,
        background: {
          xs: 'white',
          md: `linear-gradient(295deg, ${theme.palette.primary.main} 57%, white 57%)`,
        },
        borderRadius: 20,
        overflow: { xs: 'scroll', md: 'hidden' },
      })}
      elevation={4}
    >
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        alignItems="center"
        sx={{ py: 4, px: { xs: 6, md: 0 } }}
        width="100%"
        spacing={3}
      >
        {isMobile && (
          <Typography
            variant="h5"
            align="center"
            color="text.primary"
            sx={{ px: 6 }}
            fontWeight={600}
          >
            Ingresa las credenciales del <br />{' '}
            <strong>{t('National Tax Service credentials')}</strong> de tu
            empresa
          </Typography>
        )}
        <NationalTaxServiceLogoAndText width={isMobile ? '100%' : '36%'} />
        <NationalTaxServiceCredentialsForm
          source={requestingPlatform?.code}
          onCompleted={() => {
            history.push('/invoices');
          }}
          autocomplete
        />
      </Stack>
    </Card>
  );
};

export default NationalTaxServiceCredentials;
