import { useReactiveVar } from '@apollo/client';
import { shoppingCartOffersVar } from '@fingo/lib/apollo/reactive-variables/shopping-cart';
import INVOICE_WITH_DOCUMENT from '@fingo/lib/graphql/queries/invoice-with-document';
import { useInvoices, useSelectedCompany } from '@fingo/lib/hooks';
import { useMemo } from 'react';

const useDisableInternalCession = () => {
  const selectedCompany = useSelectedCompany();
  const shoppingCartOffers = useReactiveVar(shoppingCartOffersVar);
  const offerIds = shoppingCartOffers.map((offer) => offer.invoiceId);
  const { invoices } = useInvoices(INVOICE_WITH_DOCUMENT, {
    skip: !offerIds?.length,
    variables: { id_In: offerIds },
  });
  const xmlMissing = useMemo(() => invoices.some(({ documents }) => {
    const xml = documents.find(({ type }) => type.code === 'XML');
    return !xml;
  }) || !invoices.length, [invoices]);
  const missingDigitalCertificate = useMemo(
    () => (!selectedCompany.hasDigitalCertificate),
    [selectedCompany],
  );
  const missingInvoiceProviderCredentials = useMemo(
    () => (!selectedCompany.hasInvoiceProviderCredentials),
    [selectedCompany],
  );
  const disableFingoCession = useMemo(() => {
    if (missingDigitalCertificate) return true;
    if (!xmlMissing) return false;
    return missingInvoiceProviderCredentials;
  }, [missingDigitalCertificate, xmlMissing, missingInvoiceProviderCredentials]);
  const disabledFingoCessionText = useMemo(() => {
    if (missingDigitalCertificate && missingInvoiceProviderCredentials) return 'No tenemos tu certificado digital ni tus credenciales del facturador.';
    if (missingDigitalCertificate) return 'No tenemos tu certificado digital.';
    if (xmlMissing && missingInvoiceProviderCredentials) return 'Por favor carga primero los xml de los documentos que deseas ceder, o ingresa las credenciales de tu facturador en Fingo.';
    return '';
  }, [missingDigitalCertificate, missingInvoiceProviderCredentials, xmlMissing]);
  return { disableFingoCession, disabledFingoCessionText };
};

export default useDisableInternalCession;
