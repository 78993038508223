import React, { useState } from 'react';
import { FactoringOperationSummary, OkOperation } from '@fingo/lib/components/shopping-cart';
import { useCreateOperation, useShoppingCart, useStepper } from '@fingo/lib/hooks';
import { closeShoppingCart } from '@fingo/lib/helpers';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SkualoCessionInstructions from '../../components/shopping-carts/OfferShoppingCart/SkualoCessionInstructions';

const useSkualoCartSteps = () => {
  const isExternalCession = true;
  const certificatePassword = '';
  const [error, setError] = useState('');
  const [bankId, setBankId] = useState(null);
  const { currentStep, goNextStep, goBackStep } = useStepper();
  const { shoppingCartData } = useShoppingCart({ cartType: 'available-offers' });
  const [createOperation, { loading }] = useCreateOperation(
    isExternalCession,
    bankId,
    certificatePassword,
    null,
    setError,
  );
  const offerShoppingSteps = [
    {
      Component: <FactoringOperationSummary shoppingCartData={shoppingCartData} />,
      onBack: null,
      onNext: goNextStep,
      title: 'Resumen de operación',
      loading: false,
    },
    {
      Component: (
        <Box sx={{ m: 5 }}>
          <SkualoCessionInstructions
            bankId={bankId}
            setBankId={setBankId}
          />
          { error && (<Typography variant="error">{error}</Typography>)}
        </Box>
      ),
      onBack: goBackStep,
      onNext: () => {
        createOperation();
        goNextStep();
      },
      title: 'Ceder desde Skualo',
      loading,
      nextButtonText: '¡Operar con Fingo!',
    },
    {
      Component: (<OkOperation />),
      onBack: null,
      onNext: closeShoppingCart,
    },
  ];
  return { offerShoppingSteps, currentStep, goNextStep, goBackStep };
};

export default useSkualoCartSteps;
